import React from "react";

export default function IsicsLogo({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85 16"
      className={className}
      aria-hidden="true"
    >
      <defs>
        <path id="isics-path" d="M0 16h84.667V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M12.626 0H0v3.956h8.797l-7.013 7.247L4.49 14l7.013-7.247v.003l3.1-2.8.73-.659V0z"
        />
        <path
          fill="currentColor"
          d="m15.333 9.966-4-3.3v8.667h4v-1.111zM19.333 15.333h4V0h-4z"
        />
        <g>
          <path
            fill="currentColor"
            d="M33.494 16c-2.784 0-4.936-.474-6.455-1.424-1.52-.948-2.28-2.31-2.28-4.085V10.4h3.759v.247c0 1.011.376 1.708 1.13 2.089.754.382 2.036.573 3.846.573 1.693 0 2.848-.16 3.463-.48.614-.32.922-.82.922-1.501 0-.64-.239-1.094-.714-1.362-.476-.268-1.34-.474-2.592-.619L29.84 8.79c-1.648-.186-2.935-.603-3.863-1.253-.928-.65-1.392-1.625-1.392-2.925 0-.908.302-1.707.905-2.398.603-.691 1.508-1.233 2.714-1.625C29.41.196 30.895 0 32.66 0c1.763 0 3.276.206 4.541.619 1.264.413 2.239 1.032 2.923 1.857.684.825 1.027 1.846 1.027 3.064v.092h-3.724v-.185c0-.598-.139-1.104-.417-1.517-.279-.412-.772-.727-1.48-.944-.707-.216-1.699-.325-2.975-.325-1.6 0-2.714.166-3.34.496-.627.33-.94.856-.94 1.578 0 .599.232 1.032.696 1.3.464.268 1.334.485 2.61.65l4.002.495c2.041.227 3.55.67 4.524 1.33.974.661 1.461 1.63 1.461 2.91 0 .949-.336 1.77-1.009 2.46-.673.692-1.613 1.218-2.819 1.579-1.206.36-2.622.541-4.245.541"
          />
          <mask id="isics-mask" fill="#fff">
            <use xlinkHref="#isics-path" />
          </mask>
          <path
            fill="currentColor"
            mask="url(#isics-mask)"
            d="M42.841 15.69h3.887V.31h-3.887zM57.433 16c-1.903 0-3.562-.335-4.977-1.006-1.415-.67-2.511-1.61-3.288-2.816-.778-1.207-1.166-2.594-1.166-4.163 0-1.609.388-3.012 1.166-4.209.777-1.196 1.873-2.13 3.288-2.8C53.871.336 55.53 0 57.433 0c1.74 0 3.282.268 4.628.805 1.345.536 2.406 1.284 3.184 2.243.777.96 1.166 2.09 1.166 3.389v.31h-3.724V6.56c0-1.196-.464-2.089-1.392-2.677-.928-.588-2.239-.882-3.932-.882-1.949 0-3.376.418-4.28 1.253-.905.836-1.358 2.09-1.358 3.76 0 1.651.453 2.894 1.358 3.73.904.835 2.331 1.253 4.28 1.253 1.693 0 3.004-.294 3.932-.882.928-.588 1.392-1.48 1.392-2.677v-.217h3.724v.34c0 1.28-.389 2.405-1.166 3.374-.778.97-1.839 1.723-3.184 2.26-1.346.536-2.888.804-4.628.804M76.593 16c-2.784 0-4.936-.474-6.455-1.424-1.52-.948-2.28-2.31-2.28-4.085V10.4h3.76v.247c0 1.011.376 1.708 1.13 2.089.753.382 2.036.573 3.845.573 1.694 0 2.848-.16 3.463-.48.614-.32.922-.82.922-1.501 0-.64-.238-1.094-.714-1.362-.475-.268-1.34-.474-2.592-.619l-4.733-.557c-1.647-.186-2.935-.603-3.862-1.253-.928-.65-1.392-1.625-1.392-2.925 0-.908.3-1.707.904-2.398.603-.691 1.508-1.233 2.715-1.625C72.51.196 73.994 0 75.758 0c1.763 0 3.277.206 4.541.619 1.265.413 2.239 1.032 2.924 1.857.683.825 1.026 1.846 1.026 3.064v.092h-3.724v-.185c0-.598-.139-1.104-.417-1.517-.278-.412-.772-.727-1.48-.944-.707-.216-1.699-.325-2.974-.325-1.601 0-2.715.166-3.341.496-.627.33-.94.856-.94 1.578 0 .599.232 1.032.696 1.3.464.268 1.334.485 2.61.65l4.002.495c2.041.227 3.55.67 4.524 1.33.975.661 1.462 1.63 1.462 2.91 0 .949-.337 1.77-1.01 2.46-.673.692-1.612 1.218-2.818 1.579-1.207.36-2.622.541-4.246.541"
          />
        </g>
      </g>
    </svg>
  );
}
